<template>
  <label
    class="radiobtn"
    :class="{
      radiobtn_checked: isChecked,
      radiobtn_wrong: error,
      radiobtn_success: success,
    }"
  >
    <div
      class="radiobtn__back-wrap"
      :class="{ 'radiobtn__back-wrap_checked': isChecked }"
    >
      <div
        class="radiobtn__wrap"
        :class="{ radiobtn__wrap_checked: isChecked }"
      >
        <svg
          v-show="isChecked"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="10" height="10" rx="5" fill="currentColor" />
        </svg>
        <input
          type="radio"
          :id="`radiobtn_${_uid}`"
          v-model="inputVal"
          :value="inputValue"
          @input="$emit('change', inputVal)"
          :disabled="disabled"
        />
      </div>
    </div>
    <div class="radiobtn__text">
      <div
        :for="`radiobtn_${_uid}`"
        class="radiobtn__label"
        v-html="label"
      ></div>
    </div>
  </label>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    value: [Boolean, Array, Number, String],
    label: String,
    inputValue: [String, Number],
    error: Boolean,
    success: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    errorText: "",
    isError: false,
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isChecked() {
      return this.inputVal == this.inputValue;
    },
  },

  watch: {
    inputVal() {},
  },
};
</script>

<style lang="scss" scoped>
.radiobtn {
  padding: 16px 24px 16px 0;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    width: calc(100% - 10px);
    margin-left: auto;
  }

  &__back-wrap {
    margin-left: -16px;
    width: 30px;
    height: 30px;
    border-right: 1px solid #d2d2d2;
    border-radius: 50%;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &_checked {
      border-color: #830051;
    }
  }

  &__wrap {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 2px solid #1f1f1f;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: #fff;
    color: #830051;

    &_checked {
      border-color: #830051;
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1000;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
  }

  &__text {
    margin-left: 24px;
  }

  &__label {
    margin-top: 1px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1f1f1f;
  }

  &_checked {
    background-color: #830051;
    border-color: #830051;
    box-shadow: 0px 20px 35px -20px #00000099;
    & .radiobtn {
      &__label {
        color: #fff;
      }
    }
  }

  &_wrong {
    background-color: #f8dcdc;
    border-color: #cf1517;
    box-shadow: none;
    & .radiobtn {
      &__label {
        color: #1f1f1f;
      }

      &__back-wrap {
        border-color: #f8dcdc;
      }

      &__wrap {
        border-color: #cf1517;
        color: #cf1517;
      }
    }
  }

  &_success {
    background-color: #e1f3eb;
    border-color: #6bc399;
    box-shadow: none;
    & .radiobtn {
      &__label {
        color: #1f1f1f;
      }

      &__back-wrap {
        border-color: #e1f3eb;
      }

      &__wrap {
        border-color: #6bc399;
        color: #6bc399;
      }
    }
  }
}

.radiobtn_simple {
  align-items: flex-start;
  border: 0;
  background-color: transparent;
  padding: 0;

  &.radiobtn_checked {
    box-shadow: none;

    & .radiobtn {
      &__wrap {
        border-color: #830051;
      }

      &__label {
        color: #830051;
        font-weight: 600;
      }
    }
  }

  & .radiobtn {
    &__back-wrap {
      background-color: transparent;
      border: 0;
      width: auto;
      height: auto;
      margin: 0;
    }

    &__wrap {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      border: 2px solid #1f1f1f;
      background-color: #fff;
      border-radius: 50%;
      flex-shrink: 0;
      margin-top: 2px;
      color: #830051;

      & input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1000;
        margin: 0;
        padding: 0;
        cursor: pointer;
      }
    }

    &__text {
      margin-left: 16px;
    }

    &__label {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #1f1f1f;
    }
  }
}
</style>

<style lang="scss" >
.radiobtn {
  &__label {
    & b {
      font-weight: 700;
    }
  }
}
</style>